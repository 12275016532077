import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import Venue from "./components/Venue/Venue";
import Contact from "./components/Contact/Contact";
import Program from "./components/Program/Program";
import Committee from "./components/Committee/Committee";
import Registration from "./components/Registration/Registration";
import Submissions from "./components/Submissions/Submissions";
import ImportantTimes from "./components/ImportantTimes/ImportantTimes";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TouristicSights from "./components/TouristicSights /TouristicSights";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/program" element={<Program />} />
          <Route path="/importanttimes" element={<ImportantTimes />} />
          <Route path="/submissions" element={<Submissions />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/committee" element={<Committee />} />
          <Route path="/venue" element={<Venue />} />
          <Route path="/touristicsights" element={<TouristicSights />} />
          <Route path="/contact" element={<Contact />} />          
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
