import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BannerOther from "../Home/BannerOther";
import EventTimes from "./EventTimes";
// import Particle from "../Particle";

function KeyDates() {
  return (
    <section>
    <Container fluid id="important-times"  className="home-content banner-title-other heavy-shadow-text"> 
      <BannerOther mainText="Key Dates"/>
    </Container>
    <EventTimes/>
    </section>
  );
}
export default KeyDates;
