import React from 'react';
import { Table, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function EventTimes() {
    return (
        <Container>
            <dl class="row">
              <dt class="col-sm-6 text-lg-end text-center" ><p>Workshop Dates</p></dt>
              <dd class="col-sm-6 text-lg-start text-center">February 10 – 12, 2025</dd>

              <dt class="col-sm-6 text-lg-end text-center" ><p>Abstract Submission Deadline</p></dt>
              <dd class="col-sm-6 text-lg-start text-center">January 14, 2025</dd>
              <dt class="col-sm-6 text-lg-end text-center d-none" ><p>Travel Grant Application Deadline</p></dt>
              <dd class="col-sm-6 text-lg-start text-center d-none">November 20, 2024</dd>
              <dt class="col-sm-6 text-lg-end text-center" ><p>Registration Deadlines</p></dt>
              <dd class="col-sm-6 text-lg-start text-center">February 10, 2025</dd>
            </dl>
        </Container>
    );
}

export default EventTimes;